import { ILocationSettingsService } from 'api/Location/interfaces/ILocationSettingsService';
import { IProductCostSettings } from 'api/Location/model/IProductCostSettings';
import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { IProductMergeService } from 'api/Product/interfaces/IProductMergeService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { productUtils } from 'api/Product/utils/productUtils';
import { IUserAccountInfoReader } from 'api/UserAccount/interfaces/IUserAccountInfoReader';
import { IUserName } from 'api/UserAccount/model/IUserName';
import moment from 'moment-timezone';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import CoreTimeModel from 'gen-thrift/core_time_Model_types';
import ProductExceptions from 'gen-thrift/product_Exceptions_types';

import { IBreakageService } from 'api/Breakage/interfaces/IBreakageService';
import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { IDistributorService } from 'api/Distributor/interfaces/IDistributorService';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { RetailerDistributorRelationship } from 'api/Distributor/model/RetailerDistributorRelationship';
import { IInventoryCountService } from 'api/InventoryCount/interfaces/IInventoryCountService';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { ILocationService } from 'api/Location/interfaces/ILocationService';
import { LocationId } from 'api/Location/model/LocationId';
import { IOrderingService } from 'api/Ordering/interfaces/IOrderingService';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { IPrepEventService } from 'api/PrepEvent/interfaces/IPrepEventService';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { IProductDistributorService } from 'api/Product/interfaces/IProductDistributorService';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { Packaging } from 'api/Product/model/Packaging';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { IProductCostService } from 'api/Product/interfaces/IProductCostService';
import { IProductParService } from 'api/ProductPar/interfaces/IProductParService';
import { ITransferService } from 'api/Transfer/interfaces/ITransferService';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { ITEM_CARD_NUMBER_OF_MONTHS_TO_SHOW } from '../constants';
import { ComponentName, CREATE_PRODUCT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME, DEFAULT_PRICE_VALUE, IItemCardData, IItemCardState,
    IProductDistributorAssociationForm,
    IProductFormData, IProductHistoryData, ItemCardView, PackagingWeightFormFieldName, ProductDistributorAssociationFormFieldName, ProductDistributorAssociationFormValidationInputDataByFieldName, ProductFormFieldName, ProductFormValidationInputDataByFieldName } from '../reducers/ItemCardReducers';
import { DEFAULT_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME, ProductFormUtils } from '../utils/ProductFormUtils';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IExtraArguments } from 'shared/components/Provider';
import {
    addToSortedOptionList,
    createSortedOptionsAndLablNameTuplesFromOptionArray
} from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { locationService, locationSettingsService, momentObjectToThriftSerializer } from 'shared/lib/manager';
import { batchActions, IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { Validation } from 'shared/validators/validators';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { DateTime } from 'shared/models/DateTime';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { ProductDistributorAssociationFormId } from '../model/ProductDistributorAssociationFormId';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { IProductDistributorAssociationService } from 'api/Product/interfaces/IProductDistributorAssociationService';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { ProductCost } from 'api/Product/model/ProductCost';
import { numberUtils } from 'shared/utils/numberUtils';
import { decimalToNumber } from 'shared/utils/decimalUtils';

export interface IItemCardStore {
    itemCardState : IItemCardState;
}

export const ActionTypes = {
    SET_PRODUCT_ID : 'ITEM_CARD/SET_PRODUCT_ID',
    SET_SELECTED_ITEM_CARD_VIEW : 'ITEM_CARD/SET_SELECTED_ITEM_CARD_VIEW',
    SET_ITEM_CARD_DATA : 'ITEM_CARD/SET_ITEM_CARD_DATA',
    SET_PRODUCT_HISTORY_DATA : 'ITEM_CARD/SET_PRODUCT_HISTORY_DATA',
    SET_FORM_FIELD_VALIDATION_DATA : 'ITEM_CARD/SET_FORM_FIELD_VALIDATION_DATA',
    SET_FORM_VALIDATION_DATA : 'ITEM_CARD/SET_FORM_VALIDATION_DATA',
    SET_IS_SUBMITTING : 'ITEM_CARD/SET_IS_SUBMITTING',
    SET_COMPONENT_IS_SHOWN : 'ITEM_CARD/SET_COMPONENT_IS_SHOWN',
    UPDATE_PRODUCT_INFO : 'ITEM_CARD/UPDATE_PRODUCT_INFO',
    SET_PRODUCT_FORM_DATA : 'ITEM_CARD/SET_PRODUCT_FORM_DATA',
    SET_PRODUCT_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME : 'ITEM_CARD/SET_PRODUCT_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME',
    SET_UNITS_WITH_CONVERSION_FORMS : 'ITEM_CARD/SET_UNITS_WITH_CONVERSION_FORMS',
    SET_CONVERSION_FIELD_VALIDATION_DATA : 'ITEM_CARD/SET_CONVERSION_FIELD_VALIDATION_DATA',
    SET_PACKAGING_ID_HAS_PACKAGING_WEIGHT_FORM : 'ITEM_CARD/SET_PACKAGING_ID_HAS_PACKAGING_WEIGHT_FORM',
    SET_PACKAGING_WEIGHT_FIELD_VALIDATION_DATA : 'ITEM_CARD/SET_PACKAGING_WEIGHT_FIELD_VALIDATION_DATA',
    SET_PACKAGING_WEIGHT_FORM_PACKAGING_ID_VALUE : 'ITEM_CARD/SET_PACKAGING_WEIGHT_FORM_PACKAGING_ID_VALUE',
    SET_PRODUCT_DISTRIBUTOR_ASSOCIATION_FIELD_VALIDATION_DATA : 'ITEM_CARD/SET_PRODUCT_DISTRIBUTOR_ASSOCIATION_FIELD_VALIDATION_DATA',
    ADD_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM : 'ITEM_CARD/ADD_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM',
    REMOVE_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORMS : 'ITEM_CARD/REMOVE_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORMS',
    UPDATE_DISTRIBUTORS_BY_DISTRIBUTOR_ID : 'ITEM_CARD/UPDATE_DISTRIBUTORS_BY_DISTRIBUTOR_ID',
    UPDATE_NAMES_BY_USER_ACCOUNT_ID : 'ITEM_CARD/UPDATE_NAMES_BY_USER_ACCOUNT_ID',
    UPDATE_PRODUCT_CATEGORIES_BY_ID : 'ITEM_CARD/UPDATE_PRODUCT_CATEGORIES_BY_ID',
    SET_PRICE_AMOUNT_FIELD_FOCUSED : 'ITEM_CARD/PRICE_AMOUNT_FIELD_FOCUSED',
    SET_FOCUSED_FIELD : 'ITEM_CARD/SET_FOCUSED_FIELD',
};

export namespace ActionInterfaces {
    export interface ISetProductId extends IAction {
        payload : {
            productId : ProductId | null,
            productHash : string | null,
        };
    }

    export interface ISetSelectedItemCardView extends IAction {
        payload : {
            selectedItemCardView : ItemCardView
        };
    }

    export interface ISetItemCardData extends IAction {
        payload : {
            itemCardData : IItemCardData
        };
    }

    export interface ISetProductHistoryData extends IAction {
        payload : {
            productHistoryData : IProductHistoryData
        };
    }

    export interface IUpdateProductInfo extends IAction {
        payload : {
            productsById : StringValueMap<ProductId, Product>;
            distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>;
            productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>;
        };
    }

    export interface IUpdateDistributorsByDistributorId extends IAction {
        payload : {
            distributorsByDistributorId : StringValueMap<DistributorId, Distributor>;
        };
    }

    export interface IUpdateNamesByUserAccountId extends IAction {
        payload : {
            namesByUserAccountId : StringValueMap<UserAccountId, IUserName>
        };
    }

    export interface IUpdateCategoriesById extends IAction {
        payload : {
            categoriesById : StringValueMap<CategoryId, Category>
        };
    }

    export interface ISetFormFieldValidationData extends IAction {
        payload : {
            field : ProductFormFieldName;
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetFormValidationData extends IAction {
        payload : {
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetIsSubmitting extends IAction {
        payload : {
            isSubmitting : boolean;
        };
    }

    export interface ISetComponentIsShown extends IAction {
        payload : {
            componentName : ComponentName;
            isShown : boolean;
        };
    }

    export interface ISetProductFormData extends IAction {
        payload : {
            productFormData : IProductFormData,
        };
    }

    export interface ISetProductFormValidationInputDataByFieldName extends IAction {
        payload : {
            validationInputDataByFieldName : ProductFormValidationInputDataByFieldName;
        };
    }

    export interface ISetUnitsWithConversionForms extends IAction {
        payload : {
            conversionUnits : Array<string>;
        };
    }

    export interface ISetConversionFieldValidationData extends IAction {
        payload : {
            unit : string;
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetPackagingIdHasPackagingWeightForm extends IAction {
        payload : {
            packagingIdValue : string;
            hasPackagingWeightForm : boolean;
        };
    }

    export interface ISetPackagingWeightFieldValidationData extends IAction {
        payload : {
            packagingIdValue : string;
            field : PackagingWeightFormFieldName;
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetPackagingWeightFormPackagingIdValue extends IAction {
        payload : {
            oldPackagingIdValue : string;
            newPackagingIdValue : string;
        };
    }

    export interface ISetProductDistributorAssociationFieldValidationData extends IAction {
        payload : {
            formId : ProductDistributorAssociationFormId;
            field : ProductDistributorAssociationFormFieldName;
            value : string;
            isValid : boolean;
            errorMessage : string;
            packagingIndex : number | null | undefined;
        };
    }

    export interface IAddProductDistributorAssociationForm extends IAction {
        payload : {
            formId : ProductDistributorAssociationFormId;
            productDistributorAssociationForm : IProductDistributorAssociationForm;
        };
    }

    export interface IRemoveProductDistributorAssociationForms extends IAction {
        payload : {
            formIds : StringValueSet<ProductDistributorAssociationFormId>;
        };
    }

    export interface ISetPriceAmountFieldFocused extends IAction {
        payload : {
            priceAmountFieldFocused : boolean;
        };
    }

    export interface ISetFocusedField extends IAction {
        payload : {
            focusedField : ProductFormFieldName | PackagingWeightFormFieldName | null;
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        locationProductService : ILocationProductService;
        productDistributorService : IProductDistributorService;
        locationService : ILocationService;
        productService : IProductService;
        transferService : ITransferService;
        inventoryCountService : IInventoryCountService;
        orderingService : IOrderingService;
        breakageService : IBreakageService;
        distributorService : IDistributorService;
        productDistributorAssociationService : IProductDistributorAssociationService;
        prepEventService : IPrepEventService;
        productCostService : IProductCostService;
        productParService : IProductParService;
        productMergeService : IProductMergeService;
        categoryService : ICategoryService;
        locationSettingsService : ILocationSettingsService;
        userAccountInfoReader : IUserAccountInfoReader;
    }

    export interface IItemCardExtraArguments extends IExtraArguments {
        services : IServices;
    }

    export interface IItemCardActionCreatorsMapObject extends IActionCreatorsMapObject {
        fetchItemCardData : () => ThunkAction<Promise<void>, IItemCardStore, IItemCardExtraArguments>;
        onShowItemCardForProductId : (
            productId : ProductId | null,
        ) => ThunkAction<Promise<void>, IItemCardStore, IItemCardExtraArguments>;
        onUpdateDistributor : (
            distributorIds : StringValueSet<DistributorId>
        ) => ThunkAction<Promise<void>, IItemCardStore, IItemCardExtraArguments>;
        onSetSelectedItemCardView : (
            selectedItemCardView : ItemCardView
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        setComponentIsShown : ISetComponentIsShownActionCreator;
        onFormFieldChange : (
            field : ProductFormFieldName,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onFormFieldBlur : (
            field : ProductFormFieldName,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        setFormFieldValidationData : ISetFormFieldValidationDataActionCreator;
        setUnitsWithConversionForms : ISetUnitsWithConversionFormsActionCreator;
        onConversionFieldChange : (
            unit : string,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onConversionFieldBlur : (
            unit : string,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        setPackagingIdHasPackagingWeightForm : ISetPackagingIdHasPackagingWeightFormActionCreator;
        setPackagingWeightFormPackagingIdValue : ISetPackagingWeightFormPackagingIdValueActionCreator;
        onPackagingWeightFormFieldChange : (
            packagingIdValue : string,
            field : PackagingWeightFormFieldName,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onPackagingWeightFormFieldBlur : (
            packagingIdValue : string,
            field : PackagingWeightFormFieldName,
            value : string,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onAddProductDistributorAssociationForm : (
            packagingIndex : number | null,
            productDistributorAssociationFormValidationInputDataByFieldName : ProductDistributorAssociationFormValidationInputDataByFieldName | null,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onProductDistributorAssociationFormFieldChange : (
            formId : ProductDistributorAssociationFormId,
            field : ProductDistributorAssociationFormFieldName,
            value : string,
            packagingIndex : number | null | undefined,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        onProductDistributorAssociationFormFieldBlur : (
            formId : ProductDistributorAssociationFormId,
            field : ProductDistributorAssociationFormFieldName,
            value : string,
            packagingIndex : number | null | undefined,
        ) => ThunkAction<void, IItemCardStore, IItemCardExtraArguments>;
        removeProductDistributorAssociationForms : IRemoveProductDistributorAssociationFormsActionCreator;
        onSaveProductForm : (
            productId : ProductId | null,
            packagings : Array<Packaging | null>,
            shouldCheckIfPackagingHasBeenModified : boolean,
        ) => ThunkAction<Promise<ProductId | void>, IItemCardStore, IItemCardExtraArguments>;
        onSetProductActiveState : (productId : ProductId, setAsActive : boolean) => ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments>;
        onDeleteProduct : (productId : ProductId) => ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments>;
        setFocusedField : ISetFocusedFieldActionCreator;
    }
}

type ISetProductIdActionCreator = (productId : ProductId | null, productHash : string | null) => ActionInterfaces.ISetProductId;
type ISetIsSelectedItemCardViewActionCreator = (selectedItemCardView : ItemCardView) => ActionInterfaces.ISetSelectedItemCardView;
type ISetItemCardDataActionCreator = (itemCardData : IItemCardData) => ActionInterfaces.ISetItemCardData;
type ISetProductHistoryDataActionCreator = (productHistoryData : IProductHistoryData) => ActionInterfaces.ISetProductHistoryData;
type ISetFormFieldValidationDataActionCreator = (field : ProductFormFieldName, value : string, isValid : boolean, errorMessage : string) => ActionInterfaces.ISetFormFieldValidationData;
type ISetFormValidationDataActionCreator = (isValid : boolean, errorMessage : string) => ActionInterfaces.ISetFormValidationData;
type ISetIsSubmittingActionCreator = (isSubmitting : boolean) => ActionInterfaces.ISetIsSubmitting;
type ISetComponentIsShownActionCreator = (componentName : ComponentName, isShown : boolean) => ActionInterfaces.ISetComponentIsShown;
type IUpdateProductInfoActionCreator = (
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>,
) => ActionInterfaces.IUpdateProductInfo;
type IUpdateDistributorsByDistributorIdActionCreator = (
    distributorsByDistributorId : StringValueMap<DistributorId, Distributor>,
) => ActionInterfaces.IUpdateDistributorsByDistributorId;
type IUpdateNamesByUserAccountIdCreator = (
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>
) => ActionInterfaces.IUpdateNamesByUserAccountId;
type IUpdateCategoriesByIdCreator = (
    categoriesById : StringValueMap<CategoryId, Category>
) => ActionInterfaces.IUpdateCategoriesById;
type ISetProductFormDataActionCreator = (productFormData : IProductFormData) => ActionInterfaces.ISetProductFormData;
type ISetProductFormValidationInputDataByFieldNameActionCreator = (validationInputDataByFieldName : ProductFormValidationInputDataByFieldName) => ActionInterfaces.ISetProductFormValidationInputDataByFieldName;
type ISetUnitsWithConversionFormsActionCreator = (conversionUnits : Array<string>) => ActionInterfaces.ISetUnitsWithConversionForms;
type ISetConversionFieldValidationDataActionCreator = (unit : string, value : string, isValid : boolean, errorMessage : string) => ActionInterfaces.ISetConversionFieldValidationData;
type ISetPackagingIdHasPackagingWeightFormActionCreator = (packagingIdValue : string, hasPackagingWeightForm : boolean) => ActionInterfaces.ISetPackagingIdHasPackagingWeightForm;
type ISetPackagingWeightFieldValidationDataActionCreator = (packagingIdValue : string, field : PackagingWeightFormFieldName, value : string, isValid : boolean, errorMessage : string) => ActionInterfaces.ISetPackagingWeightFieldValidationData;
type ISetPackagingWeightFormPackagingIdValueActionCreator = (packagingIdValue : string, oldPackagingIdValue : string) => ActionInterfaces.ISetPackagingWeightFormPackagingIdValue;
type ISetProductDistributorAssociationFieldValidationDataActionCreator = (formId : ProductDistributorAssociationFormId, field : ProductDistributorAssociationFormFieldName, value : string, isValid : boolean, errorMessage : string, packagingIndex : number | null | undefined) => ActionInterfaces.ISetProductDistributorAssociationFieldValidationData;
type IAddProductDistributorAssociationFormActionCreator = (formId : ProductDistributorAssociationFormId, productDistributorAssociationForm : IProductDistributorAssociationForm) => ActionInterfaces.IAddProductDistributorAssociationForm;
type IRemoveProductDistributorAssociationFormsActionCreator = (formIds : StringValueSet<ProductDistributorAssociationFormId>) => ActionInterfaces.IRemoveProductDistributorAssociationForms;
type ISetFocusedFieldActionCreator = (fieldName: ProductFormFieldName | PackagingWeightFormFieldName | null) => ActionInterfaces.ISetFocusedField;

const setProductId : ISetProductIdActionCreator = (
    productId : ProductId | null,
    productHash : string | null,
) : ActionInterfaces.ISetProductId => ({
    payload: {
        productId,
        productHash
    },
    type: ActionTypes.SET_PRODUCT_ID
});

const setSelectedItemCardView : ISetIsSelectedItemCardViewActionCreator = (
    selectedItemCardView : ItemCardView
) : ActionInterfaces.ISetSelectedItemCardView => ({
    payload: {
        selectedItemCardView
    },
    type: ActionTypes.SET_SELECTED_ITEM_CARD_VIEW
});

const setItemCardData : ISetItemCardDataActionCreator = (
    itemCardData : IItemCardData
) : ActionInterfaces.ISetItemCardData => ({
    payload: {
        itemCardData
    },
    type: ActionTypes.SET_ITEM_CARD_DATA
});

const setProductHistoryData : ISetProductHistoryDataActionCreator = (
    productHistoryData : IProductHistoryData
) : ActionInterfaces.ISetProductHistoryData => ({
    payload: {
        productHistoryData
    },
    type: ActionTypes.SET_PRODUCT_HISTORY_DATA
});

const updateProductInfo : IUpdateProductInfoActionCreator = (
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>,
) : ActionInterfaces.IUpdateProductInfo => ({
    payload: {
        productsById,
        distributorIdsByProductId,
        productDistributorAssociationsByProductId
    },
    type: ActionTypes.UPDATE_PRODUCT_INFO
});

const updateDistributorsByDistributorId : IUpdateDistributorsByDistributorIdActionCreator = (
    distributorsByDistributorId : StringValueMap<DistributorId, Distributor>,
) : ActionInterfaces.IUpdateDistributorsByDistributorId => ({
    payload: {
        distributorsByDistributorId,
    },
    type: ActionTypes.UPDATE_DISTRIBUTORS_BY_DISTRIBUTOR_ID
});

const updateNamesByUserAccountId : IUpdateNamesByUserAccountIdCreator = (
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>
) : ActionInterfaces.IUpdateNamesByUserAccountId => ({
    payload: {
        namesByUserAccountId
    },
    type: ActionTypes.UPDATE_NAMES_BY_USER_ACCOUNT_ID
});

const updateCategoriesById : IUpdateCategoriesByIdCreator = (
    categoriesById : StringValueMap<CategoryId, Category>
) : ActionInterfaces.IUpdateCategoriesById => ({
    payload: {
        categoriesById
    },
    type: ActionTypes.UPDATE_PRODUCT_CATEGORIES_BY_ID
});

const setFormFieldValidationData : ISetFormFieldValidationDataActionCreator = (
    field : ProductFormFieldName,
    value : string,
    isValid : boolean,
    errorMessage : string
) : ActionInterfaces.ISetFormFieldValidationData => ({
    payload : {
        field,
        value,
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_FIELD_VALIDATION_DATA,
});

const setFormValidationData : ISetFormValidationDataActionCreator = (
    isValid : boolean,
    errorMessage : string
) : ActionInterfaces.ISetFormValidationData => ({
    payload : {
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_VALIDATION_DATA,
});

const setIsSubmitting : ISetIsSubmittingActionCreator = (
    isSubmitting : boolean
) : ActionInterfaces.ISetIsSubmitting => ({
    payload : {
        isSubmitting,
    },
    type : ActionTypes.SET_IS_SUBMITTING,
});

const setComponentIsShown : ISetComponentIsShownActionCreator = (
    componentName : ComponentName,
    isShown : boolean,
) : ActionInterfaces.ISetComponentIsShown => ({
    payload: {
        isShown,
        componentName,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN
});

const setProductFormData : ISetProductFormDataActionCreator = (
    productFormData : IProductFormData,
) : ActionInterfaces.ISetProductFormData => ({
    payload: {
        productFormData,
    },
    type: ActionTypes.SET_PRODUCT_FORM_DATA
});

const setProductFormValidationInputDataByFieldName : ISetProductFormValidationInputDataByFieldNameActionCreator = (
    validationInputDataByFieldName : ProductFormValidationInputDataByFieldName
) : ActionInterfaces.ISetProductFormValidationInputDataByFieldName => ({
    payload: {
        validationInputDataByFieldName
    },
    type: ActionTypes.SET_PRODUCT_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME
});

const setUnitsWithConversionForms : ISetUnitsWithConversionFormsActionCreator = (
    conversionUnits : Array<string>,
) : ActionInterfaces.ISetUnitsWithConversionForms => ({
    payload: {
        conversionUnits,
    },
    type: ActionTypes.SET_UNITS_WITH_CONVERSION_FORMS
});

const setConversionFieldValidationData : ISetConversionFieldValidationDataActionCreator = (
    unit : string,
    value : string,
    isValid : boolean,
    errorMessage : string,
) : ActionInterfaces.ISetConversionFieldValidationData => ({
    payload: {
        unit,
        value,
        isValid,
        errorMessage,
    },
    type: ActionTypes.SET_CONVERSION_FIELD_VALIDATION_DATA
});

const setPackagingIdHasPackagingWeightForm : ISetPackagingIdHasPackagingWeightFormActionCreator = (
    packagingIdValue : string,
    hasPackagingWeightForm : boolean,
) : ActionInterfaces.ISetPackagingIdHasPackagingWeightForm => ({
    payload: {
        packagingIdValue,
        hasPackagingWeightForm,
    },
    type: ActionTypes.SET_PACKAGING_ID_HAS_PACKAGING_WEIGHT_FORM
});

const setPackagingWeightFieldValidationData : ISetPackagingWeightFieldValidationDataActionCreator = (
    packagingIdValue : string,
    field : PackagingWeightFormFieldName,
    value : string,
    isValid : boolean,
    errorMessage : string
) : ActionInterfaces.ISetPackagingWeightFieldValidationData => ({
    payload: {
        packagingIdValue,
        field,
        value,
        isValid,
        errorMessage,
    },
    type: ActionTypes.SET_PACKAGING_WEIGHT_FIELD_VALIDATION_DATA
});

const setPackagingWeightFormPackagingIdValue : ISetPackagingWeightFormPackagingIdValueActionCreator = (
    oldPackagingIdValue : string,
    newPackagingIdValue : string
) : ActionInterfaces.ISetPackagingWeightFormPackagingIdValue => ({
    payload: {
        oldPackagingIdValue,
        newPackagingIdValue,
    },
    type: ActionTypes.SET_PACKAGING_WEIGHT_FORM_PACKAGING_ID_VALUE
});

const setProductDistributorAssociationFieldValidationData : ISetProductDistributorAssociationFieldValidationDataActionCreator = (
    formId : ProductDistributorAssociationFormId,
    field : ProductDistributorAssociationFormFieldName,
    value : string,
    isValid : boolean,
    errorMessage : string,
    packagingIndex : number | null | undefined,
) : ActionInterfaces.ISetProductDistributorAssociationFieldValidationData => ({
    payload: {
        formId,
        field,
        value,
        isValid,
        errorMessage,
        packagingIndex,
    },
    type: ActionTypes.SET_PRODUCT_DISTRIBUTOR_ASSOCIATION_FIELD_VALIDATION_DATA
});

const addProductDistributorAssociationForm : IAddProductDistributorAssociationFormActionCreator = (
    formId : ProductDistributorAssociationFormId,
    productDistributorAssociationForm : IProductDistributorAssociationForm,
) : ActionInterfaces.IAddProductDistributorAssociationForm => ({
    payload: {
        formId,
        productDistributorAssociationForm,
    },
    type: ActionTypes.ADD_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM
});

const removeProductDistributorAssociationForms : IRemoveProductDistributorAssociationFormsActionCreator = (
    formIds : StringValueSet<ProductDistributorAssociationFormId>,
) : ActionInterfaces.IRemoveProductDistributorAssociationForms => ({
    payload: {
        formIds,
    },
    type: ActionTypes.REMOVE_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORMS
});

const setFocusedField : ISetFocusedFieldActionCreator = (
    focusedField : ProductFormFieldName | PackagingWeightFormFieldName | null,
) : ActionInterfaces.ISetFocusedField => ({
    payload: {
        focusedField,
    },
    type: ActionTypes.SET_FOCUSED_FIELD
});

const fetchItemCardData = (
) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const currentUserAccountId = window.GLOBAL_USER_ID;
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        const nonDeletedProductIds = new StringValueSet<ProductId>();
        const allProductIds = new StringValueSet<ProductId>();
        let activeProductIds : StringValueSet<ProductId>;
        let allProductsById : StringValueMap<ProductId, Product>;
        let availableDistributorsByIdForLocation : StringValueMap<DistributorId, Distributor>;
        let retailerDistributorRelationships : StringValueMap<DistributorId, RetailerDistributorRelationship>;
        let distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>;
        let categoriesById : StringValueMap<CategoryId, Category>;
        let productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>;
        let productCostSettings : IProductCostSettings;

        return Promise.all([
            extraArguments.services.locationProductService.getActiveProductIds(userSessionId, locationId)
            .then((productIds) => {
                activeProductIds = productIds;
            }),
            extraArguments.services.locationProductService.getProductIds(userSessionId, locationId)
            .then((productIds) => {
                productIds.forEach((productId) => {
                    nonDeletedProductIds.add(productId);
                    allProductIds.add(productId);
                });
            }),
            extraArguments.services.locationProductService.getDeletionEventsByProductId(userSessionId, locationId)
            .then((resultDeletionEventsByProductId) => {
                Array.from(resultDeletionEventsByProductId.keys()).forEach((productId) => {
                    allProductIds.add(productId);
                });
            }),
            extraArguments.services.categoryService.getCategoriesForRetailer(userSessionId, locationId)
            .then((resultCategoriesById) => {
                categoriesById = resultCategoriesById;
            }),
            extraArguments.services.locationSettingsService.getRetailerSettingsInformation(locationId.getValue())
            .then((result) => { productCostSettings = {
                includeDeposits : result.product_cost.include_deposits,
                includeTaxes : result.product_cost.include_taxes,
                includeDiscounts : result.product_cost.include_discounts};
            })
        ])
        .then(() => {
            return Promise.all([
                extraArguments.services.productService.getProductsById(userSessionId, locationId, allProductIds)
                .then((resultProductsById) => {
                    allProductsById = resultProductsById.productsById;
                }),
                extraArguments.services.distributorService.getAvailableDistributorsByIdForLocation(userSessionId, locationId)
                .then((resultAvailableDistributorsByIdForLocation) => {
                    availableDistributorsByIdForLocation = resultAvailableDistributorsByIdForLocation;
                }),
                extraArguments.services.distributorService.getRetailerDistributorRelationships(locationId)
                .then((resultRetailerDistributorRelationships) => {
                    retailerDistributorRelationships = resultRetailerDistributorRelationships;
                }),
                extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(userSessionId, Array.from(nonDeletedProductIds.values()))
                .then((resultDistributorIdsByProductId) => {
                    distributorIdsByProductId = resultDistributorIdsByProductId;
                }),
                extraArguments.services.productDistributorAssociationService.getProductDistributorAssociationsForProductIds(userSessionId, new StringValueSet(nonDeletedProductIds.values()))
                .then((resultProductDistributorAssociationsByProductId) => {
                    productDistributorAssociationsByProductId = resultProductDistributorAssociationsByProductId;
                }),
            ]);
        })
        .then(() => {
            const glCodes = new Set<string>();
            const productTypes = new Set<string>();
            const glCodeIOptions : Array<IOption> = [];
            const customGlCodeIOptions : Array<IOption> = [];
            const productTypeIOptions : Array<IOption> = [];
            const relevantUserAccountIds = new StringValueSet<UserAccountId>();
            const namesByUserAccountId = new StringValueMap<UserAccountId, IUserName>();

            const nonDeletedProducts = new Set<Product>();

            relevantUserAccountIds.add(new UserAccountId(currentUserAccountId));
            nonDeletedProductIds.forEach((productId) => {
                const product = allProductsById.get(productId);
                if (typeof product !== 'undefined') {
                    nonDeletedProducts.add(product);
                }
            });

            categoriesById.forEach((category) => {
                if (!category.getIsDeleted()) {
                    const glCode = category.getGlCode().trim();

                    if (glCode && !glCodes.has(glCode)) {
                        glCodes.add(glCode);
                        glCodeIOptions.push({
                            value: glCode,
                            label: CategoryUtils.createCategoryLabelName(category),
                            icon: null,
                        });
                    }
                }
            });

            nonDeletedProducts.forEach((product) => {
                const productType = product.getProductType().trim();
                const productGLCode = product.getGLCode().trim();

                if (productType && !productTypes.has(productType)) {
                    productTypes.add(productType);
                    productTypeIOptions.push({
                        value: productType,
                        label: productType,
                        icon: null,
                    });
                }

                if (productGLCode && !glCodes.has(productGLCode)) {
                    glCodes.add(productGLCode);
                    customGlCodeIOptions.push({
                        value: productGLCode,
                        label: productGLCode,
                        icon: null,
                    });
                }

                relevantUserAccountIds.add(product.getLastUpdateEvent().getUserAccountId());
            });

            const userAccountIdsList = Array.from(relevantUserAccountIds.values());
            let userAccountPromise : Promise<Array<IUserName> | null>;
            if (userAccountIdsList.length > 0) {
                userAccountPromise = Promise.all(userAccountIdsList.map((u) => {
                    return extraArguments.services.userAccountInfoReader.getNameForAccountId(u);
                }));
            } else {
                userAccountPromise = Promise.resolve(null);
            }

            return Promise.resolve(userAccountPromise.then((resultNamesByUserAccountId) => {
                if (resultNamesByUserAccountId) {
                    userAccountIdsList.forEach((userId, index) => {
                        namesByUserAccountId.set(userId, resultNamesByUserAccountId[index]);
                    });
                }
            })).then(() => {
                const categorySortedOptionsAndLabelNames = CategoryUtils.getCategorySortedOptionsAndLabelNames(categoriesById, null);
                const productTypeSortedOptionsAndLabelNames =
                    createSortedOptionsAndLablNameTuplesFromOptionArray(productTypeIOptions, null);
                const connectedGlCodeSortedOptionsAndLabelNames =
                    createSortedOptionsAndLablNameTuplesFromOptionArray(glCodeIOptions, 'Connected GL Codes');
                const customGlCodeSortedOptionsAndLabelNames =
                    createSortedOptionsAndLablNameTuplesFromOptionArray(customGlCodeIOptions, 'Custom GL Codes');
                const glCodeSortedOptionsAndLabelNames = [
                    connectedGlCodeSortedOptionsAndLabelNames[0],
                    customGlCodeSortedOptionsAndLabelNames[0],
                ];

                const distributorIdsWithRelationship = new StringValueSet(Array.from(retailerDistributorRelationships.keys()));
                const distributorSortedOptionsAndLabelNames = ProductFormUtils.getDistributorSortedOptionsAndLabelNames(availableDistributorsByIdForLocation, distributorIdsWithRelationship, true);

                dispatch(batchActions([
                    setItemCardData({
                        activeProductIds,
                        productsById: allProductsById,
                        namesByUserAccountId,
                        distributorsByDistributorId: availableDistributorsByIdForLocation,
                        distributorIdsByProductId,
                        distributorIdsWithRelationship,
                        productDistributorAssociationsByProductId,
                        categoriesById,
                        productCostSettings,
                    }),
                    setProductFormData({
                        distributorSortedOptionsAndLabelNames,
                        glCodeSortedOptionsAndLabelNames,
                        categorySortedOptionsAndLabelNames,
                        productTypeSortedOptionsAndLabelNames,
                    }),
                ]));
            });
        });
    };
};

const fetchProductHistoryData = (
) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const deliveryDistributorIds = new StringValueSet<DistributorId>();

        let inventoryMetadataByInventoryId : StringValueMap<InventoryCountId, InventoryCountMetadata>;
        let deliveriesByDeliveryId : StringValueMap<DeliveryId, Delivery>;
        let transferIdSet : StringValueSet<TransferId>;
        let transferReportsByTransferId : StringValueMap<TransferId, TransferReport>;
        let breakageIdSet : StringValueSet<BreakageId>;
        let breakageReportsByBreakageId : StringValueMap<BreakageId, BreakageReport>;
        let inventoryCountIds : StringValueSet<InventoryCountId>;
        let inventoryCountsByInventoryId : StringValueMap<InventoryCountId, InventoryCount>;
        let locationNamesByLocationId : StringValueMap<LocationId, string>;
        let distributorsByDistributorId : StringValueMap<DistributorId, Distributor>;
        let prepEventIdSet : StringValueSet<PrepEventId>;
        let prepEventsByPrepEventId : StringValueMap<PrepEventId, PrepEvent>;
        let productMergeEventsByProductId : StringValueMap<ProductId, Array<ProductMergeEvent>>;

        const periodEndDate = new Date();
        const periodEndDateInMillisecondsSinceEpoch = periodEndDate.getTime();

        let periodStartDateInMillisecondsSinceEpoch : number;
        let numMonthsOfData : number = ITEM_CARD_NUMBER_OF_MONTHS_TO_SHOW;
        const defaultStartDateInMillisecondsSinceEpoch = new Date().setMonth(periodEndDate.getMonth() - ITEM_CARD_NUMBER_OF_MONTHS_TO_SHOW);
        if (window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS === null) {
            periodStartDateInMillisecondsSinceEpoch = defaultStartDateInMillisecondsSinceEpoch;
        } else {
            const startDateBasedOnDataHistoryLimit = moment().subtract(window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS, 'days');
            periodStartDateInMillisecondsSinceEpoch = Math.max(defaultStartDateInMillisecondsSinceEpoch, startDateBasedOnDataHistoryLimit.toDate().getTime());
            if (periodStartDateInMillisecondsSinceEpoch !== defaultStartDateInMillisecondsSinceEpoch) {
                // third argument is "precise" -- i.e. a float if true instead of an integer. default is false, just being explicit about argument here
                numMonthsOfData = moment().diff(startDateBasedOnDataHistoryLimit, 'months', false);
            }
        }

        const periodStartTime = new CoreTimeModel.TimestampWithMillisecondPrecision({
            timeSinceUnixEpoch: new CoreTimeModel.Milliseconds({ value: periodStartDateInMillisecondsSinceEpoch })
        });
        const periodEndTime = new CoreTimeModel.TimestampWithMillisecondPrecision({
            timeSinceUnixEpoch: new CoreTimeModel.Milliseconds({ value: periodEndDateInMillisecondsSinceEpoch })
        });

        const periodStartTimeMoment = moment(periodStartTime.timeSinceUnixEpoch.value);
        const periodEndTimeMoment = moment(periodEndTime.timeSinceUnixEpoch.value);

        return Promise.all([
            extraArguments.services.inventoryCountService.getFinalizedInventoryCountMetadatasByIdForLocationInPeriod(userSessionId, locationId, periodStartTime, periodEndTime)
            .then((resultInventoryMetadatasByInventoryId) => {
                inventoryCountIds = new StringValueSet<InventoryCountId>(Array.from(resultInventoryMetadatasByInventoryId.keys()));
                inventoryMetadataByInventoryId = resultInventoryMetadatasByInventoryId;
            }),
            extraArguments.services.orderingService.getDeliveriesForLocationInPeriod(userSessionId, locationId, periodStartTimeMoment, periodEndTimeMoment)
            .then((resultDeliveriesByDeliveryId) => {
                deliveriesByDeliveryId = resultDeliveriesByDeliveryId;
                resultDeliveriesByDeliveryId.forEach((delivery) => {
                    const distributorId = delivery.getDistributorId();
                    if (distributorId) {
                        deliveryDistributorIds.add(distributorId);
                    }
                });
            }),
            extraArguments.services.transferService.getTransferReportsForLocationInPeriod(userSessionId, locationId, periodStartTimeMoment, periodEndTimeMoment)
            .then((resultTransferIdSet) => {
                transferIdSet = resultTransferIdSet;
            }),
            extraArguments.services.breakageService.getBreakageReportsForLocationInPeriod(userSessionId, locationId, periodStartTimeMoment, periodEndTimeMoment)
            .then((resultBreakageIdSet) => {
                breakageIdSet = resultBreakageIdSet;
            }),
            extraArguments.services.prepEventService.getPrepEventIdsForLocationInPeriod(userSessionId, locationId, periodStartTimeMoment, periodEndTimeMoment)
            .then((resultPrepEventSet) => {
                prepEventIdSet = resultPrepEventSet;
            }),
            extraArguments.services.productMergeService.getMergeEventsForRetailer(userSessionId, locationId)
            .then((resultMergeEvents) => {
                productMergeEventsByProductId = resultMergeEvents;
            })
        ])
        .then(() => {
            return Promise.all([
                extraArguments.services.transferService.getTransferReportsById(userSessionId, transferIdSet, locationId)
                .then((resultTransferReportsByTransferId) => {
                    transferReportsByTransferId = resultTransferReportsByTransferId;
                    const locationIds = new StringValueSet<LocationId>();
                    transferReportsByTransferId.forEach((transferReport : TransferReport) => {
                        const partnerLocation = transferReport.getTransferReportData().getPartnerLocation();
                        if (partnerLocation instanceof LocationId) {
                            locationIds.add(partnerLocation);
                        }
                    });

                    return locationService.getLocationNamesById(locationIds)
                    .then((resultLocationNamesByLocationId) => {
                        locationNamesByLocationId = resultLocationNamesByLocationId;
                    });
                }),
                extraArguments.services.distributorService.getDistributorsById(deliveryDistributorIds)
                .then((resultDistributorsByDistributorId) => {
                    distributorsByDistributorId = resultDistributorsByDistributorId;
                }),
                extraArguments.services.breakageService.getBreakageReportsById(userSessionId, breakageIdSet)
                .then((resultBreakageReportsByBreakageId) => {
                    breakageReportsByBreakageId = resultBreakageReportsByBreakageId;
                }),
                extraArguments.services.inventoryCountService.getInventoryCountsById(userSessionId, inventoryCountIds)
                .then((resultInventoryCountsByInventoryId) => {
                    inventoryCountsByInventoryId = resultInventoryCountsByInventoryId;
                }),
                extraArguments.services.prepEventService.getPrepEventsById(userSessionId, prepEventIdSet)
                .then((prepEventsByIdResult) => {
                    prepEventsByPrepEventId = prepEventsByIdResult;
                })
            ]);
        })
        .then(() => {
            const productHistoryData = {
                inventoryMetadataByInventoryId,
                deliveriesByDeliveryId,
                transferReportsByTransferId,
                breakageReportsByBreakageId,
                inventoryCountsByInventoryId,
                prepEventsByPrepEventId,
                locationNamesByLocationId,
                productMergeEventsByProductId,
                numMonthsOfData,
            };

            dispatch(batchActions([
                updateDistributorsByDistributorId(distributorsByDistributorId),
                setProductHistoryData(productHistoryData),
            ]));
        });
    };
};

const onShowItemCardForProductId = (
    productId : ProductId | null,
) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const itemCardData = getState().itemCardState.itemCardData;

        dispatch(batchActions([
            setComponentIsShown('productModificationConfirmationDialog', false),
            setIsSubmitting(false),
            setFormValidationData(true, ''),
        ]));

        if (productId) {
            return Promise.all([
                extraArguments.services.productService.getProductAndProductHash(userSessionId, productId),
                extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(userSessionId, [productId]),
                extraArguments.services.productCostService.getCurrentProductCostsByProductId(userSessionId, new StringValueSet([productId]), locationId),
                extraArguments.services.productParService.getParsByProductId(userSessionId, new StringValueSet([productId])),
                extraArguments.services.productDistributorAssociationService.getProductDistributorAssociationsForProductIds(userSessionId, new StringValueSet([productId])),
            ])
            .then((results) => {
                const { product, productHash } = results[0];
                const distributorIdsByProductId = results[1];
                const currentProductCostsByProductId = results[2];
                const parByProductId = results[3];
                const productDistributorAssociationsByProductId = results[4];

                const productsById = new StringValueMap<ProductId, Product>();
                productsById.set(productId, product);

                const distributorId = distributorIdsByProductId.get(productId);
                const productCost = currentProductCostsByProductId.get(productId);
                const par = parByProductId.get(productId);
                const userAccountId = product.getLastUpdateEvent().getUserAccountId();

                if ((typeof distributorId === 'undefined') || (typeof productCost === 'undefined') || (typeof par === 'undefined')) {
                    throw new RuntimeException(`lookup for productId: ${ productId.getValue() } unexpectedly was undefined`);
                }

                const categoryIdSet = new StringValueSet<CategoryId>();
                const categoryId = product.getNewProductCategoryId();
                if (categoryId) {
                    categoryIdSet.add(categoryId);
                }

                return Promise.resolve(
                    categoryId ? extraArguments.services.categoryService.getCategoriesById(userSessionId, locationId, categoryIdSet) : new StringValueMap<CategoryId, Category>()
                ).then((newCategoriesById) => {
                    const relevantDistributorIds = new StringValueSet<DistributorId>();
                    if (distributorId) {
                        relevantDistributorIds.add(distributorId);
                    }
                    productDistributorAssociationsByProductId.forEach((productDistributorAssociations) => {
                        productDistributorAssociations.forEach((productDistributorAssociation) => {
                            relevantDistributorIds.add(productDistributorAssociation.getDistributorId());
                        });
                    });
                    return ((relevantDistributorIds.size === 0) ? Promise.resolve() : dispatch(onUpdateDistributor(relevantDistributorIds)))
                    .then(() => {
                        return (
                            (itemCardData != null && itemCardData.namesByUserAccountId.has(userAccountId)) ?
                                Promise.resolve(itemCardData.namesByUserAccountId.getRequired(userAccountId)) :
                                Promise.resolve(extraArguments.services.userAccountInfoReader.getNameForAccountId(userAccountId)))
                        .then((name : IUserName) => {
                            const namesByUserAccountId = new StringValueMap<UserAccountId, IUserName>();
                            namesByUserAccountId.set(userAccountId, name);

                            const productDistributorAssociations = Array.from(productDistributorAssociationsByProductId.getRequired(productId).values());

                            dispatch(batchActions([
                                setProductId(productId, productHash),
                                updateNamesByUserAccountId(namesByUserAccountId),
                                updateProductInfo(productsById, distributorIdsByProductId, productDistributorAssociationsByProductId),
                                setProductFormValidationInputDataByFieldName(ProductFormUtils.getValidationInputDataByFieldName(product, distributorId, productCost, par, productDistributorAssociations)),
                                updateCategoriesById(newCategoriesById),
                                setComponentIsShown('itemCardModal', true),
                            ]));
                        });
                    });
                });
            });
        } else {
            dispatch(batchActions([
                setProductId(productId, null),
                setProductFormValidationInputDataByFieldName(CREATE_PRODUCT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME),
                setComponentIsShown('itemCardModal', true),
            ]));

            return Promise.resolve();
        }
    };
};

const onFormFieldChange = (
    field : ProductFormFieldName,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        const itemCardState = getState().itemCardState;
        const itemCardData = itemCardState.itemCardData;
        // If changing category and the product GL code is defaulted to the category, match gl code with new category
        if (itemCardData && field === 'categoryId') {
            const oldCategoryId = new CategoryId(itemCardState.productForm.validationInputDataByFieldName.categoryId.value);
            const oldGlCode = itemCardState.productForm.validationInputDataByFieldName.glCode.value;
            const categoryId = new CategoryId(value);
            const categoriesById = itemCardData.categoriesById;
            if (categoriesById.has(categoryId) && categoriesById.has(oldCategoryId) && categoriesById.getRequired(oldCategoryId).getGlCode() === oldGlCode) {
                dispatch(onFormFieldChange('glCode', itemCardData.categoriesById.getRequired(categoryId).getGlCode()));
            }
        }

        const { isValid, errorMessage } = ProductFormUtils.validateValueByFieldName(field, value);
        dispatch(setFormFieldValidationData(field, value, isValid, errorMessage));
    };
};

const onFormFieldBlur = (
    field : ProductFormFieldName,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        let blurValue = value.trim();
        if ((field === 'priceAmount') || (field === 'unitDeposit')) {
            if (blurValue === '') {
                blurValue = DEFAULT_PRICE_VALUE;
            }
        }

        const { isValid, errorMessage } = ProductFormUtils.validateValueByFieldName(field, blurValue);

        if (isValid) {
            if ((field === 'priceAmount') || (field === 'unitDeposit') || (field === 'costAmount')) {
                blurValue = numberUtils.FormatToMinumumTwoAndMaximumThreeDecimalPlaces(parseFloat(blurValue));
            }
        }

        dispatch(setFormFieldValidationData(field, blurValue, isValid, errorMessage));

        if (isValid) {
            if (field === 'priceAmount') {
                // set/confirm cost
                const {
                    productForm,
                    itemCardData,
                } = getState().itemCardState;
                if (!itemCardData) {
                    throw new RuntimeException("item card data not fully loaded");
                }
                const {
                    includeDeposits,
                    includeDiscounts,
                    includeTaxes
                } = itemCardData.productCostSettings;
                // sync price to cost ONLY IF account is configured to account for cost inputs beyond price
                if (includeDeposits || includeDiscounts || includeTaxes) {
                    dispatch(setFormFieldValidationData('costAmount', productForm.validationInputDataByFieldName.costAmount.value, false, 'Please verify this value.'));
                    dispatch(setFormValidationData(false, 'Please verify the Cost field.'));
                } else {
                    dispatch(setFormFieldValidationData('costAmount', blurValue, false, 'This is updated to match the Price field.'));
                    dispatch(setFormValidationData(true, ''));
                }
            } else {
                dispatch(setFormValidationData(true, ''));
            }
        }
    };
};

const onConversionFieldChange = (
    unit : string,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        const isValid = Validation.validateRequired(value) && Validation.validateNumber(value) && Validation.validateGreaterThanZero(value);
        let errorMessage = '';
        if (!isValid) {
            errorMessage = 'Invalid value';
        }

        dispatch(setConversionFieldValidationData(unit, value, isValid, errorMessage));
    };
};

const onConversionFieldBlur = (
    unit : string,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        let blurValue = value.trim();
        if (blurValue === '') {
            blurValue = '1';
        }

        const isValid = Validation.validateRequired(blurValue) && Validation.validateNumber(blurValue);

        let errorMessage = '';
        if (!isValid) {
            errorMessage = 'Invalid value';
        }

        dispatch(setConversionFieldValidationData(unit, blurValue, isValid, errorMessage));

        if (isValid) {
            dispatch(setFormValidationData(true, ''));
        }
    };
};

const onPackagingWeightFormFieldChange = (
    packagingIdValue : string,
    field : PackagingWeightFormFieldName,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        const { isValid, errorMessage } = ProductFormUtils.validatePackagingWeightValueByFieldName(field, value);
        dispatch(setPackagingWeightFieldValidationData(packagingIdValue, field, value, isValid, errorMessage));
    };
};

const onPackagingWeightFormFieldBlur = (
    packagingIdValue : string,
    field : PackagingWeightFormFieldName,
    value : string,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        const blurValue = value.trim();
        const { isValid, errorMessage } = ProductFormUtils.validatePackagingWeightValueByFieldName(field, blurValue);
        dispatch(setPackagingWeightFieldValidationData(packagingIdValue, field, blurValue, isValid, errorMessage));

        if (isValid) {
            dispatch(setFormValidationData(true, ''));
        }
    };
};

const onAddProductDistributorAssociationForm = (
    packagingIndex : number | null,
    productDistributorAssociationFormValidationInputDataByFieldName : ProductDistributorAssociationFormValidationInputDataByFieldName | null,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        let validationInputDataByFieldName = { ...DEFAULT_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME };
        if (productDistributorAssociationFormValidationInputDataByFieldName !== null) {
            validationInputDataByFieldName = productDistributorAssociationFormValidationInputDataByFieldName;
        }

        dispatch(addProductDistributorAssociationForm(
            ProductDistributorAssociationFormId.generateUniqueProductDistributorAssociationFormId(),
            {
                validationInputDataByFieldName,
                isValid: true,
                errorMessage: '',
                packagingIndex,
            }
        ));
    };
};

const onProductDistributorAssociationFormFieldChange = (
    formId : ProductDistributorAssociationFormId,
    field : ProductDistributorAssociationFormFieldName,
    value : string,
    packagingIndex : number | null | undefined,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        const { isValid, errorMessage } = ProductFormUtils.validateProductDistributorAssociationValueByFieldName(field, value);
        dispatch(setProductDistributorAssociationFieldValidationData(formId, field, value, isValid, errorMessage, packagingIndex));
    };
};

const onProductDistributorAssociationFormFieldBlur = (
    formId : ProductDistributorAssociationFormId,
    field : ProductDistributorAssociationFormFieldName,
    value : string,
    packagingIndex : number | null | undefined,
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        let blurValue = value.trim();
        if ((field === 'priceAmount') || (field === 'depositAmount')) {
            if (blurValue === '') {
                blurValue = DEFAULT_PRICE_VALUE;
            }
        }

        const { isValid, errorMessage } = ProductFormUtils.validateProductDistributorAssociationValueByFieldName(field, blurValue);

        if (isValid) {
            if ((field === 'priceAmount') || (field === 'depositAmount')) {
                blurValue = numberUtils.FormatToMinumumTwoAndMaximumThreeDecimalPlaces(parseFloat(blurValue));
            }
        }

        dispatch(setProductDistributorAssociationFieldValidationData(formId, field, blurValue, isValid, errorMessage, packagingIndex));

        if (isValid) {
            dispatch(setFormValidationData(true, ''));
        }
    };
};

const onUpdateDistributor = (
    distributorIds : StringValueSet<DistributorId>,
) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        return extraArguments.services.distributorService.getDistributorsById(distributorIds, true)
        .then((distributorsByDistributorId) => {
            dispatch(updateDistributorsByDistributorId(distributorsByDistributorId));
        });
    };
};

const onSetSelectedItemCardView = (
    selectedItemCardView : ItemCardView
) : ThunkAction<void, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : void => {
        if (selectedItemCardView === 'productHistory') {
            const productHistoryData = getState().itemCardState.productHistoryData;
            if (productHistoryData === null) {
                dispatch(fetchProductHistoryData());
            }
        }

        dispatch(setSelectedItemCardView(selectedItemCardView));
    };
};

const onSaveProductForm = (
    productId : ProductId | null,
    packagings : Array<Packaging | null>,
    shouldCheckIfPackagingHasBeenModified : boolean,
) : ThunkAction<Promise<ProductId | void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<ProductId | void> => {
        dispatch(setIsSubmitting(true));

        let formIsValid = true;

        const validationInputDataByFieldName = getState().itemCardState.productForm.validationInputDataByFieldName;
        Object.keys(validationInputDataByFieldName).forEach((key) => {
            let isValid : boolean;

            if (key === 'conversions') {
                const conversions = validationInputDataByFieldName.conversions;
                let allConversionsAreValid = true;

                Object.keys(conversions).forEach((productQuantityUnitValue) => {
                    const value = conversions[productQuantityUnitValue].value;
                    const conversionIsValid = Validation.validateRequired(value) && Validation.validateNumber(value) && Validation.validateGreaterThanZero(value);
                    let errorMessage = '';
                    if (!conversionIsValid) {
                        errorMessage = 'Invalid value';
                    }
            
                    dispatch(setConversionFieldValidationData(productQuantityUnitValue, value, conversionIsValid, errorMessage));
                    allConversionsAreValid = allConversionsAreValid && conversionIsValid;
                });

                isValid = allConversionsAreValid;
            } else if (key === 'packagingWeights') {
                const packagingWeights = validationInputDataByFieldName.packagingWeights;
                let allPackagingWeightsAreValid = true;

                Object.keys(packagingWeights).forEach((packagingIdValue) => {
                    const packagingWeightFormValidationInputDataByFieldName = packagingWeights[packagingIdValue];
                    Object.keys(packagingWeightFormValidationInputDataByFieldName).forEach((packagingWeightKey) => {
                        const fieldName = packagingWeightKey as PackagingWeightFormFieldName;
                        const value = (packagingWeightFormValidationInputDataByFieldName)[fieldName].value;
            
                        const validationResult = ProductFormUtils.validatePackagingWeightValueByFieldName(fieldName, value);
                        allPackagingWeightsAreValid = allPackagingWeightsAreValid && validationResult.isValid;
        
                        dispatch(setPackagingWeightFieldValidationData(packagingIdValue, fieldName, value, validationResult.isValid, validationResult.errorMessage));
                    });
                });

                isValid = allPackagingWeightsAreValid;
            } else if (key === 'productDistributorAssociations') {
                const productDistributorAssociations = validationInputDataByFieldName.productDistributorAssociations;
                let allProductDistributorAssociations = true;

                productDistributorAssociations.forEach((productDistributorAssociationForm, productDistributorAssociationFormId) => {
                    const productDistributorAssociationFormValidationInputDataByFieldName = productDistributorAssociationForm.validationInputDataByFieldName;
                    Object.keys(productDistributorAssociationFormValidationInputDataByFieldName).forEach((productDistributorAssociationFormFieldName) => {
                        const fieldName = productDistributorAssociationFormFieldName as ProductDistributorAssociationFormFieldName;
                        const value = productDistributorAssociationFormValidationInputDataByFieldName[fieldName].value;

                        const validationResult = ProductFormUtils.validateProductDistributorAssociationValueByFieldName(fieldName, value);
                        allProductDistributorAssociations = allProductDistributorAssociations && validationResult.isValid;

                        dispatch(setProductDistributorAssociationFieldValidationData(productDistributorAssociationFormId, fieldName, value, validationResult.isValid, validationResult.errorMessage, productDistributorAssociationForm.packagingIndex));
                    });
                });

                isValid = allProductDistributorAssociations;
            } else {
                const fieldName = key as ProductFormFieldName;
                const value = (validationInputDataByFieldName)[fieldName].value;
    
                const validationResult = ProductFormUtils.validateValueByFieldName(fieldName, value);
                isValid = validationResult.isValid;

                dispatch(setFormFieldValidationData(fieldName, value, isValid, validationResult.errorMessage));
            }

            formIsValid = formIsValid && isValid;
        });

        const validPackagings : Array<Packaging> = [];
        packagings.forEach((packaging) => {
            if (packaging !== null) {
                validPackagings.push(packaging);
            }
        });

        const allPackagingsAreValid = (validPackagings.length > 0) && (packagings.length === validPackagings.length);

        if (formIsValid && allPackagingsAreValid) {
            dispatch(setFormValidationData(formIsValid, ''));

            const itemCardState = getState().itemCardState;
            const {
                itemCardData,
                productForm,
                productHash,
                productFormData,
            } = itemCardState;

            if (!itemCardData) {
                throw new RuntimeException('itemCardData is unexpectedly null');
            }

            let savedProduct : Product | null = null;
            let savedProductDistributorAssociations = new StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>();
            if (productId) {
                savedProduct = itemCardData.productsById.getRequired(productId);
                savedProductDistributorAssociations = itemCardData.productDistributorAssociationsByProductId.getRequired(productId);
            }

            const productAndDistributorAndPar = ProductFormUtils.getProductAndDistributorIdAndPar(
                productForm.validationInputDataByFieldName,
                validPackagings,
                savedProduct,
                new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), DateTime.now().toTimestampWithMillisecondPrecision()),
                PackagingUtils.generatePackagingId);
            let product = productAndDistributorAndPar.product;
            const distributorId = productAndDistributorAndPar.distributorId;
            const par = productAndDistributorAndPar.par;
            const productCost = productAndDistributorAndPar.productCost;
            const getProductDistributorAssociations = productAndDistributorAndPar.getProductDistributorAssociations;
            const userSessionId = extraArguments.services.userSessionReader.getSessionId();
            const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

            const newCategoryId = product.getNewProductCategoryId();
            const newCategoriesById = new StringValueMap(itemCardData.categoriesById);
            let newCategoryPromise : Promise<CategoryId | null>;
            let newCategoryName = product.getProductCategoryId();
            if (newCategoryId && !itemCardData.categoriesById.has(newCategoryId)) {
                // Need to create a new category
                const newCategory = new Category(
                    newCategoryId.getValue(),
                    '',
                    false,
                    ''
                );
                newCategoryName = newCategoryId.getValue();
                newCategoryPromise = extraArguments.services.categoryService.createCategory(userSessionId, locationId, newCategory)
                    .then((categoryInformation) => {
                        newCategoriesById.set(categoryInformation.category_id, new Category(newCategoryId.getValue(), '', false, categoryInformation.category_hash));
                        dispatch(setItemCardData({
                            ...itemCardData,
                            categoriesById: newCategoriesById,
                        }));
                        return Promise.resolve(categoryInformation.category_id);
                    });
            } else if (newCategoryId) {
                newCategoryName = itemCardData.categoriesById.getRequired(newCategoryId).getName();
                newCategoryPromise = Promise.resolve(newCategoryId);
            } else {
                newCategoryPromise = Promise.resolve(newCategoryId);
            }
            return newCategoryPromise.then((categoryId) => {
                let productIdPromise : Promise<ProductId>;
                product = productUtils.getProductWithNewCategory(product, categoryId, newCategoryName);

                if (categoryId) {
                    // If the product GL code matches its category's gl code, remove gl code from the product mongo object(inherit from category)
                    const category = itemCardData.categoriesById.get(categoryId);
                    if (typeof category !== 'undefined' && category.getGlCode().trim() === product.getGLCode().trim()) {
                        product = productUtils.getProductWithNewGLCode(product, '');
                    }
                }

                if (productId) {
                    if (!savedProduct || !productHash) {
                        throw new RuntimeException(`expected product and productHash for productId`);
                    }

                    if (shouldCheckIfPackagingHasBeenModified) {
                        const packagingsAndMappings = product.getPackagingsAndMappings();
                        const savedPackagingsAndMappings = savedProduct.getPackagingsAndMappings();

                        let packagingsAreEqual = packagingsAndMappings.getConversions().equals(savedPackagingsAndMappings.getConversions());
                        packagingsAreEqual = packagingsAreEqual && (packagingsAndMappings.getPackagingData().length === savedPackagingsAndMappings.getPackagingData().length);

                        if (packagingsAreEqual) {
                            packagingsAndMappings.getPackagingData().forEach((data, index) => {
                                const otherData = savedPackagingsAndMappings.getPackagingData()[index];
                                packagingsAreEqual = packagingsAreEqual && data.packaging.equals(otherData.packaging);
                            });
                        }

                        if (!packagingsAreEqual) {
                            let allPackagingsAreContained = true;
                            Array.from(savedPackagingsAndMappings.getAvailablePackagingByPackagingId().keys()).forEach((packagingId) => {
                                allPackagingsAreContained = allPackagingsAreContained && packagingsAndMappings.getAvailablePackagingByPackagingId().has(packagingId);
                            });

                            if (!allPackagingsAreContained) {
                                dispatch(setIsSubmitting(false));
                                dispatch(setComponentIsShown('productModificationConfirmationDialog', true));
                                return Promise.resolve();
                            }
                        }
                    } else {
                        dispatch(setComponentIsShown('productModificationConfirmationDialog', false));
                    }

                    productIdPromise = extraArguments.services.productService.editProduct(userSessionId, locationId, productId, product, productHash)
                    .then(() => Promise.resolve(productId));
                } else {
                    productIdPromise = extraArguments.services.productService.createProduct(userSessionId, locationId, product);
                }

                return productIdPromise
                .then((resultProductId) => {
                    if (!productId) {
                        extraArguments.services.locationProductService.setProductAsActive(userSessionId, locationId, resultProductId);
                    }

                    // If there were any product categories or product types added, add them to the form data.
                    dispatch(setProductFormData({
                        ...productFormData,
                        categorySortedOptionsAndLabelNames: CategoryUtils.getCategorySortedOptionsAndLabelNames(newCategoriesById, null),
                        productTypeSortedOptionsAndLabelNames:
                            addToSortedOptionList(
                                productFormData.productTypeSortedOptionsAndLabelNames,
                                product.getProductType(),
                                null),
                    }));

                    let newProductCost: ProductCost | null = productCost;

                    let distributorAssociationPromise;
                    if (window.GLOBAL_FEATURE_ACCESS.multi_vendor) {
                        const updatedProductDistributorAssociations = getProductDistributorAssociations(resultProductId);
                        const productDistributorAssociationsToRemove: Array<ProductDistributorAssociation> = [];

                        const updatedProductDistributorAssociationsByIdentifier = new StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>();
                        updatedProductDistributorAssociations.forEach((productDistributorAssociation) => {
                            updatedProductDistributorAssociationsByIdentifier.set(
                                ProductDistributorAssociationIdentifier.fromProductDistributorAssociation(productDistributorAssociation),
                                productDistributorAssociation
                            );

                            // We want to set the productCost to one of the ProductDistributorAssociation prices if the user hasn't selected any value
                            // We also want to set the productCost if the cost is set to 0
                            if (!newProductCost || (productCost && decimalToNumber(productCost.getCost()) === 0)) {
                                const productDistributorAssociationPrice = productDistributorAssociation.getPrice();
                                if (productDistributorAssociationPrice) {
                                    newProductCost = productDistributorAssociationPrice;
                                }
                            }
                        });

                        savedProductDistributorAssociations.forEach((productDistributorAssociation, productDistributorAssociationIdentifier) => {
                            // TODO Maybe also check for cases when updates are not actually needed
                            if (!updatedProductDistributorAssociationsByIdentifier.has(productDistributorAssociationIdentifier)) {
                                productDistributorAssociationsToRemove.push(productDistributorAssociation);
                            }
                        });

                        distributorAssociationPromise = Promise.all([
                            (updatedProductDistributorAssociations.length > 0) ? extraArguments.services.productDistributorAssociationService.createOrUpdateProductDistributorAssociations(userSessionId, updatedProductDistributorAssociations) : Promise.resolve(),
                            (productDistributorAssociationsToRemove.length > 0) ? extraArguments.services.productDistributorAssociationService.disassociateProductDistributorAssociations(userSessionId, productDistributorAssociationsToRemove) : Promise.resolve(),
                        ])
                        .then(() => {
                            return Promise.resolve();
                        });
                    } else {
                        if (distributorId) {
                            distributorAssociationPromise = extraArguments.services.productDistributorService.associateDistributorIdWithProductId(userSessionId, resultProductId, distributorId)
                            .catch((error) => {
                                if (!(error instanceof ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException)) {
                                    throw error;
                                }
                            });
                        } else {
                            distributorAssociationPromise = extraArguments.services.productDistributorService.disassociateDistributorIdFromProductIds(userSessionId, [resultProductId]);
                        }
                    }

                    const clientTimestamp = momentObjectToThriftSerializer.getThriftTimestampFromMoment(moment());
                    let setParPromise: Promise<void>;
                    if (par) { // TODO Maybe check if this actually needs to be updated
                        const parsByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();
                        parsByProductId.set(resultProductId, par);

                        setParPromise = extraArguments.services.productParService.setParsForProductId(userSessionId, parsByProductId, clientTimestamp);
                    } else {
                        setParPromise = extraArguments.services.productParService.unsetParForProductId(userSessionId, resultProductId, clientTimestamp);
                    }

                    let setProductCostPromise: Promise<void> = Promise.resolve();
                    if (newProductCost) { // TODO Cheezy do we want an exception if this isn't set
                        setProductCostPromise = extraArguments.services.productCostService.setCurrentProductCost(userSessionId, resultProductId, newProductCost, clientTimestamp);
                    }

                    return Promise.all([
                        distributorAssociationPromise,
                        setParPromise,
                        setProductCostPromise,
                    ])
                    .then(() => {
                        Promise.all([
                            extraArguments.services.productService.getProductAndProductHash(userSessionId, resultProductId),
                            extraArguments.services.productDistributorAssociationService.getProductDistributorAssociationsForProductIds(userSessionId, new StringValueSet([resultProductId])),
                        ])
                        .then((results) => {
                            const resultProduct = results[ 0 ].product;
                            const productDistributorAssociationsByProductId = results[ 1 ];

                            const productsById = new StringValueMap<ProductId, Product>();
                            productsById.set(resultProductId, resultProduct);

                            const distributorIdsByProductId = new StringValueMap<ProductId, DistributorId | null>();
                            distributorIdsByProductId.set(resultProductId, distributorId);

                            dispatch(updateProductInfo(productsById, distributorIdsByProductId, productDistributorAssociationsByProductId));
                        });

                        return resultProductId;
                    });
                });
            });
        } else {
            dispatch(setFormValidationData(false, allPackagingsAreValid ? 'Invalid Form Field' : 'Item packaging has missing or invalid information'));
            dispatch(setIsSubmitting(false));
            return Promise.resolve();
        }
    };
};

const onDeleteProduct = (productId : ProductId) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        const currentItemCardData = getState().itemCardState.itemCardData;
        if (currentItemCardData === null) {
            throw new RuntimeException('unexpected'); // should not be possible to get into this state...
        }

        const productIdSet = new StringValueSet<ProductId>();
        productIdSet.add(productId);
        return extraArguments.services.locationProductService.deleteProducts(userSessionId, locationId, productIdSet)
        .then(() => {
            dispatch(setComponentIsShown('deleteProductConfirmationDialog', false));
            const newActiveProductIds = new StringValueSet(currentItemCardData.activeProductIds);
            newActiveProductIds.delete(productId);
            dispatch(setItemCardData({
                ...currentItemCardData,
                activeProductIds: newActiveProductIds,
            }));
            dispatch(setComponentIsShown('itemCardModal', false));
        });
    };
};

const onSetProductActiveState = (productId : ProductId, setAsActive : boolean) : ThunkAction<Promise<void>, IItemCardStore, ActionInterfaces.IItemCardExtraArguments> => {
    return (dispatch : Dispatch<IItemCardStore>, getState : () => IItemCardStore, extraArguments : ActionInterfaces.IItemCardExtraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        const currentItemCardData = getState().itemCardState.itemCardData;
        if (currentItemCardData === null) {
            throw new RuntimeException('unexpected'); // should not be possible to get into this state...
        }

        const setActiveStatePromise = setAsActive ?
            extraArguments.services.locationProductService.setProductAsActive(userSessionId, locationId, productId) :
            extraArguments.services.locationProductService.setProductAsInactive(userSessionId, locationId, productId);

        // TODO do we want to disable buttons or something?
        return setActiveStatePromise
        .then(() => {
            const newActiveProductIds = new StringValueSet(currentItemCardData.activeProductIds);
            if (setAsActive) {
                newActiveProductIds.add(productId);
            } else {
                newActiveProductIds.delete(productId);
            }
            dispatch(setItemCardData({
                ...currentItemCardData,
                activeProductIds: newActiveProductIds,
            }));

            dispatch(setComponentIsShown('itemCardModal', false));
        });
    };
};

export const ItemCardActions : ActionInterfaces.IItemCardActionCreatorsMapObject = {
    fetchItemCardData,
    onShowItemCardForProductId,
    onSetSelectedItemCardView,
    setComponentIsShown,
    onFormFieldChange,
    onFormFieldBlur,
    setUnitsWithConversionForms,
    onConversionFieldChange,
    onConversionFieldBlur,
    setFormFieldValidationData,
    setPackagingIdHasPackagingWeightForm,
    setPackagingWeightFormPackagingIdValue,
    onPackagingWeightFormFieldChange,
    onPackagingWeightFormFieldBlur,
    onAddProductDistributorAssociationForm,
    onProductDistributorAssociationFormFieldChange,
    onProductDistributorAssociationFormFieldBlur,
    removeProductDistributorAssociationForms,
    onSaveProductForm,
    onUpdateDistributor,
    onDeleteProduct,
    onSetProductActiveState,
    setFocusedField
};
