import { RuntimeException } from "shared/lib/general/exceptions/RuntimeException";

export class NormalizedPackaging {
    constructor (
        private readonly name : string | null,
        private readonly content : NormalizedPackaging | null,
        private readonly quantityOfContent : number | null,
        private readonly unit : string | null,
    ) {
        if (unit) {
            if (name !== null) {
                throw new RuntimeException('name must be null when unit exists');
            }

            if (content !== null) {
                throw new RuntimeException('content must be null when unit exists');
            }

            if (quantityOfContent !== null) {
                throw new RuntimeException('quantityOfContent must be null when unit exists');
            }
        } else {
            if (name === null) { // PTODO
                throw new RuntimeException('content must not be null when unit does not exist');
            }

            if ((content === null) !== (quantityOfContent === null)) {
                throw new RuntimeException('content and quantityOfContent must either both exist or both be null when unit does not exist');
            }
        }
    }

    public getName() : string | null {
        return this.name;
    }

    public getContent() : NormalizedPackaging | null {
        return this.content;
    }

    public getQuantityOfContent() : number | null {
        return this.quantityOfContent;
    }

    public getUnit() : string | null {
        return this.unit;
    }
}
